<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Print Grade Sheet
            <v-spacer></v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <transition
                  v-if="
                    false &&
                      status === 1 &&
                      form.items.data.length > 1 &&
                      $auth.can('marks-generate-bulk') &&
                      $auth.loggedInRole().slug === 'administrator' &&
                      selected.length > 0
                  "
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <v-btn
                    v-on="on"
                    title="Annual Mark Sheet Download"
                    fab
                    dark
                    small
                    color="warning"
                    @click="SMSModalshow"
                  >
                    <v-icon>sms</v-icon>
                  </v-btn>
                </transition>
              </template>
              <span>Send SMS</span>
            </v-tooltip>

            <v-download-column-select
              v-if="
                ($auth.isAdmin() ||
                  $auth.loggedInRole().slug === 'administrator') &&
                  $auth.can('marks-generate-bulk') &&
                  markSheetType !== 'rating' &&
                  form.items.data.length > 0
              "
              :optionType="'radio'"
              :showPDF="false"
              :columnHeader="'DOWNLOAD TYPES'"
              :downloadExcelText="'Begin Download/Print'"
              :downloadColumns="dynamicColumns"
              @onDownload="downloadResult"
            >
              DOWNLOAD BULK RECORD
            </v-download-column-select>

            <!-- <download-button
              size="x-small"
              @action=""
              v-if="
                markSheetType !== 'rating' &&
                  status === 1 &&
                  form.items.data.length > 1 &&
                  $auth.can('marks-generate-bulk') &&
                  selectedExam.is_final === 1
              "
            >
              ANNUAL GS
            </download-button> -->

            <!-- <download-button
              size="x-small"
              @action="
                (issueDateDialog = true),
                  (issueDate = today),
                  (printType = 'terminal'),
                  (singlePrintEnable = false)
              "
              v-if="
                status === 1 &&
                  form.items.data.length > 1 &&
                  $auth.can('marks-generate-bulk')
              "
            >
              TERMINAL BULK GS
            </download-button> -->

            <!-- <download-button
              size="x-small"
              v-if="
                status === 1 &&
                  form.items.data.length > 1 &&
                  $auth.can('marks-ledger-generate-bulk') &&
                  markSheetType !== 'rating'
              "
              @action="
                (printType = 'terminal'),
                  printLedger(true),
                  (singlePrintEnable = false)
              "
            >
              TERMINAL LEDGER
            </download-button> -->

            <!-- <download-button
              size="x-small"
              @action="
                (printType = 'annual'),
                  printLedger(true),
                  (singlePrintEnable = false)
              "
              v-if="
                status === 1 &&
                  form.items.data.length > 1 &&
                  $auth.can('marks-ledger-generate-bulk') &&
                  selectedExam.is_final === 1 &&
                  markSheetType !== 'rating'
              "
            >
              ANNUAL LEDGER
            </download-button> -->

            <!-- <download-button
              size="x-small"
              color="orange"
              icon="visibility"
              @action="
                (printType = 'terminal'),
                  printLedger(false),
                  (singlePrintEnable = false)
              "
              v-if="
                status === 1 &&
                  form.items.data.length > 1 &&
                  $auth.can('marks-ledger-generate-bulk') &&
                  markSheetType !== 'rating'
              "
            >
              PREVIEW(TERMINAL) LEDGER
            </download-button> -->

            <!-- <download-button
              size="x-small"
              color="orange"
              @action="
                (printType = 'annual'),
                  printLedger(false),
                  (singlePrintEnable = false)
              "
              v-if="
                status === 1 &&
                  form.items.data.length > 1 &&
                  $auth.can('marks-ledger-generate-bulk') &&
                  selectedExam.is_final === 1 &&
                  markSheetType !== 'rating'
              "
            >
              PREVIEW(ANNUAL) LEDGER
            </download-button> -->
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <!--{{$auth.user()}}-->
              <v-flex xs3 sm4>
                <v-select
                  :loading="examLoading"
                  :disabeld="!exams.length"
                  :items="exams"
                  class="pa-0"
                  label="Exam"
                  v-model="exam"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm3>
                <v-select
                  :loading="gradeLoading"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  :disabled="!exam || !grades.length"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm3>
                <v-select
                  :items="sections"
                  class="pa-0"
                  label="Section"
                  v-model="section"
                  :disabled="!grade"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>
          <br />
          <v-card-title
            style="margin-top: -12px;
    margin-bottom: -24px;"
            v-if="status === 0 && form.items.data.length"
          >
            <alert-message type="error">
              <template v-slot:title>Entry/Verification Pending</template>
              <template v-slot:message
                >Please check, verification or marks entry might still be
                pending for
                <span v-if="verfied && verfied.length > 0">
                  <span
                    class="subject-error"
                    v-for="(x, key) in verfied"
                    :key="key"
                  >
                    {{ x }}
                  </span>
                </span>
                <span v-if="academic && academic.length > 0">
                  <span
                    class="subject-error"
                    v-for="(y, key) in academic"
                    :key="key"
                  >
                    {{ y }}
                  </span>
                </span>
              </template>
            </alert-message>

            <br />
            <br />
          </v-card-title>
          <v-container v-if="selectedGrade && selectedGrade.is_published === 1">
            <alert-message
              type="success"
              :message="
                `${selectedExam.text} has been published for grade ${selectedGrade.text}. The published result can be accessed by guardians and students via school's website or mobile app`
              "
              title="Exam has been published."
            ></alert-message>
          </v-container>
          <!-- v-model="selected" -->
          <v-data-table
            v-model="selectedStudents"
            :headers="headers"
            :hide-default-footer="
              form.items.data.length < 1 ||
                form.items.meta.total < pagination.itemsPerPage
            "
            :items="form.items.data"
            :loading="form.loading"
            :options.sync="pagination"
            :footer-props="footerProps"
            class="marks_entry"
          >
            <!--show-select-->

            <template v-slot:headers="props">
              <tr>
                <th width="5">SMS(Limit {{ smsLimit }})</th>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="[
                    header.sortable ? 'column sortable' : '',
                    pagination.descending ? 'desc' : 'asc',
                    header.align === 'left' ? 'text-xs-left' : '',
                    ,
                    header.align === 'right' ? 'text-xs-right' : '',
                  ]"
                >
                  <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template v-slot:item="{ item }">
              <tr :active="selected" @click="selected = !selected">
                <td class="text-xs-left">{{ item.roll }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">
                  <span v-if="markSheetType !== 'rating'">
                    <view-more-or-less
                      limit="50"
                      v-if="item.remark"
                      :paragraph="item.remark.remarks"
                    ></view-more-or-less>
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
                <td class="text-right">
                  <download-button
                    v-if="isECA()"
                    @action="printECA(item.enroll_id)"
                    >ECA
                  </download-button>
                  &nbsp;

                  <download-button
                    v-if="
                      status === 1 &&
                        $auth.can('marks-generate-bulk') &&
                        selectedExam.is_final === 1 &&
                        markSheetType !== 'rating'
                    "
                    @action="printSingleModal(item.enroll_id, 'annual')"
                    >Annual GS
                  </download-button>

                  &nbsp;
                  <download-button
                    v-if="status === 1 && $auth.can('marks-generate-bulk')"
                    @action="printSingleModal(item.enroll_id, 'terminal')"
                  >
                    Terminal GS
                  </download-button>
                  &nbsp;
                  <download-button
                    color="orange"
                    v-if="
                      selectedGrade.is_published === 0 &&
                        status === 1 &&
                        $auth.can('marks-generate-bulk') &&
                        ($auth.user().class_teacher ||
                          myRoleSlug === 'super' ||
                          myRoleSlug === 'administrator') &&
                        markSheetType !== 'rating'
                    "
                    icon="comment"
                    @action="addRemarks(item)"
                    >Remarks
                  </download-button>
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="issueDateDialog" persistent max-width="290px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Issue Date</span>
        </v-card-title>
        <v-card-text class="pb-1">
          <br />
          <v-form
            ref="form"
            @submit.prevent="save"
            @keydown.native="form.errors.clear($event.target.name)"
            @keyup.enter="save"
            lazy-validation
          >
            <v-text-field
              outlined
              label="From Date"
              v-model="issueDate"
              :mask="'####-##-##'"
              :rules="dateValidation"
              placeholder="YYYY-MM-DD"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="warning" outlined @click="issueDateDialog = false"
            >Close</v-btn
          >
          <v-btn
            small
            outlined
            v-if="singlePrintEnable"
            color="success"
            @click="printSingle"
            >Print Single
          </v-btn>
          <v-btn small v-else color="success" outlined @click="printBulk"
            >Print Bulk</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendSMSModal" persistent max-width="450px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Send SMS</span>
        </v-card-title>
        <v-card-text>
          <alert-message
            type="warning"
            :extraSmall="true"
            title="Marks verified?"
            message="Please verify all the student's obtained GPA before you send message. "
          ></alert-message>
        </v-card-text>

        <v-card-text v-if="templateType === 'custom'" class="pb-0">
          <v-form ref="form" lazy-validation>
            <v-textarea
              v-model="customSMSText"
              label="Input custom message"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-text v-else>
          <v-form ref="form" lazy-validation>
            <v-textarea
              disabled
              label="Message sent"
              :value="
                `Dear Parent,

Congratulations! Your child 'Student_Name' ${selectedGrade.text}(${
                  selectedSection.text
                }) scored '3.4' gpa in ${selectedExam.text}. ${$auth.company(
                  'name'
                )}`
              "
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-text class="pt-0">
          <v-radio-group
            class="pt-0 mt-0"
            v-model="templateType"
            :mandatory="true"
          >
            <v-radio label="Send Custom SMS" :value="'custom'"></v-radio>
            <v-radio label="Send Default Template" :value="'default'"></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-text class="pt-0">
          <v-checkbox
            style="margin-right: 20px;"
            color="blue"
            v-model="isVerified"
          >
            <template v-slot:label>
              <div style="color:#333;">
                Verified?
                <p style="line-height: 10px">
                  <small style="color:#666;font-size: 10px;">
                    I've verified and confirmed all the student's obtained
                    grades and GPA.
                  </small>
                </p>
              </div>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="sendSMSModal = false"
            >Close</v-btn
          >
          <v-btn
            color="success"
            :disabled="
              (templateType === 'custom' && !customSMSText.length) ||
                !isVerified
            "
            outline
            @click="sendSMS"
          >
            Send SMS
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="remarksDialog" persistent max-width="590px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Add Remarks</span>
        </v-card-title>
        <v-card-text class="pb-1">
          <br />
          <v-textarea
            @keyup="remarksChanged"
            outlined
            :label="
              'Remarks for ' +
                remarkFor.name +
                ', GPA Scored :' +
                (remarkFor.gpa + ` ${remarkFor.status}` || ' -')
            "
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="remarksDialog = false"
            >Close</v-btn
          >
          <v-btn color="success" outlined @click="submitRemarks">Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import NavFactory from "../../../../library/NavFactory";
import {
  passDataToExternalDomain,
  validateDate,
} from "../../../../library/helpers";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    selected: [],
    selectedStudents: [],
    selectedStudentIds: [],
    downloadColumns: [
      { value: "terminal_gs", name: "Terminal Gradesheet", selected: false },
      {
        value: "terminal_ledger",
        name: "Terminal Ledger",
        selected: false,
      },

      {
        value: "preview_terminal_ledger",
        name: "Preview Terminal Ledger",
        selected: false,
        color: "warning",
      },
    ],
    sendSMSModal: false,
    isVerified: false,
    isCustomSMS: false,
    customSMSText: "",
    templateType: "default",
    smsLimit: 20,
    direction: "top",
    printType: "terminal",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    issueDate: nd.format("YYYY-MM-DD"),
    today: nd.format("YYYY-MM-DD"),
    status: 0,
    academic: [],
    verfied: [],
    left: false,
    transition: "slide-y-reverse-transition",
    //////////////
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    examLoading: false,
    gradeLoading: false,
    subjectLoading: false,
    issueDateDialog: false,
    singlePrintEnable: false,
    singlePrintEnrollId: "",
    singlePrintType: "",
    form: new Form(
      {
        grade_id: "",
        subject_id: "",
        section_id: "",
        exam_id: "",
        students: [],
        is_verify: 0,
      },
      "/api/marksheet-lists"
    ),
    search: null,
    pagination: {
      itemsPerPage: 100,
      sortBy: ["roll"],
    },
    markSheetType: "grading",
    rowsPerPageItems: [5, 10, 25, 50, 75],
    is_verify: 0,
    dataVerfiy: false,
    subject: "",
    subjects: [],
    section: "",
    sections: [],
    exam: "",
    exams: [],
    grade: "",
    grades: [],
    subject_marks: {},
    remarksDialog: false,
    enroll_id: "",
    remarks: "",
    remarkFor: {},
    myRoleSlug: "",
    headers: [
      {
        text: "Roll",
        align: "left",
        value: "roll",
        width: 10,
        sortable: false,
      },
      {
        text: "Name",
        align: "left",
        value: "name",
        width: 160,
        sortable: false,
      },
      {
        text: "Remarks",
        align: "left",
        value: "remarks",
        sortable: false,
        width: 300,
      },
      // {text: 'GPA', align: 'left', value: 'gpa', sortable: false},
      {
        text: "Action",
        align: "right",
        value: "theory",
        sortable: false,
        width: 300,
      },
    ],
    disablePdf: false,
  }),
  computed: {
    ...mapState(["batch"]),
    subjectName() {
      let subjectName = "";
      if (this.subject) {
        let $this = this;
        this.subjects.map(function(subject) {
          if (subject.value === $this.subject) subjectName = subject.text;
        });
      }
      return subjectName;
    },
    absentStudentsCount() {
      let count = 0;
      this.form.items.data.map((item) => {
        if (item.theory === "Absent" || item.practical === "Absent") {
          count++;
        }
      });
      return count;
    },
    selectedExam() {
      let selectedExam = {};
      let $this = this;
      this.exams.map(function(exam) {
        if (exam.value === $this.exam) {
          selectedExam = exam;
        }
      });
      return selectedExam;
    },
    selectedGrade() {
      let selectedGrade = {};
      let $this = this;
      this.grades.map(function(grade) {
        if (grade.value === $this.grade) {
          selectedGrade = grade;
        }
      });
      return selectedGrade;
    },
    selectedSection() {
      let selectedSection = {};
      let $this = this;
      this.sections.map(function(section) {
        if (section.value === $this.section) {
          selectedSection = section;
        }
      });
      return selectedSection;
    },

    dynamicColumns() {
      let columns = this.downloadColumns;
      if (this.selectedExam.is_final === 1) {
        columns.push({
          value: "annual_gs",
          name: "Annual Gradesheet",
          selected: false,
        });
        columns.push({
          value: "annual_ledger",
          name: "Annual Ledger",
          selected: false,
        });
        columns.push({
          value: "preview_annual_ledger",
          name: "Preview Annual Ledger",
          selected: false,
          color: "warning",
        });
      }
      return columns;
    },
  },
  mounted() {
    this.getExams();
    this.myRoleSlug = this.$storage.get("_role");
  },
  watch: {
    batch: function(value) {
      this.get();
    },
    grade: function(v) {
      this.section = "";
      this.subject = "";
      this.form.items.data = [];
      if (v) {
        this.getSections();
        this.getGradeRatingStatus();
      }
    },
    exam: function() {
      this.grade = "";
      this.form.items.data = [];
      this.getGrades();
    },
    subject: function() {
      this.form.items.data = [];
    },
    section: function() {
      this.get();
    },
  },
  methods: {
    passDataToExternalDomain,
    isECA: NavFactory.isEca,
    downloadResult({ type, columns, columnString }) {
      switch (columnString) {
        case "annual_gs":
          this.issueDateDialog = true;
          this.issueDate = this.today;
          this.printType = "annual";
          this.singlePrintEnable = false;
          break;
        case "terminal_gs":
          this.issueDateDialog = true;
          this.issueDate = this.today;
          this.printType = "terminal";
          this.singlePrintEnable = false;
          break;
        case "annual_ledger":
          this.printType = "annual";
          this.printLedger(true);
          this.singlePrintEnable = false;
          break;
        case "terminal_ledger":
          this.printType = "terminal";
          this.printLedger(true);
          this.singlePrintEnable = false;
          break;
        case "preview_terminal_ledger":
          this.printType = "terminal";
          this.printLedger(false);
          this.singlePrintEnable = false;
          break;
        case "preview_annual_ledger":
          this.printType = "annual";
          this.printLedger(false);
          this.singlePrintEnable = false;
          break;
      }
    },
    remarksChanged(e) {
      this.remarks = e.target.value;
    },
    sendSMS() {
      let msg_type = this.isCustomSMS ? "custom" : "default";
      this.$rest
        .post("/sms/api/sms/send-result", {
          message: this.customSMSText,
          sms_length: this.customSMSText.length,
          selected_student_ids: this.selectedStudentIds,
          template_type: this.templateType,
          msg_type: 1,
          grade_id: this.grade,
          section_id: this.section,
          exam_id: this.exam,
        })
        .then(({ data }) => {
          this.sendSMSModal = false;
          this.customSMSText = "";
          this.selectedStudentIds = "";
          this.selected = [];
          this.templateType = "default";
        });
    },
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&examId=" +
        this.exam +
        "&sectionId=" +
        this.section +
        "&gradeId=" +
        this.grade
      );
    },

    get(params) {
      if (this.section) {
        let query = [null, undefined].includes(params)
          ? this.queryString()
          : params;
        this.form.get(null, query).then(({ data }) => {
          this.verfied = data.verify.subject;
          this.academic = data.academic.subject;
          if (data.verify.status === 1 && data.academic.status === 1)
            this.status = 1;
          else this.status = 0;
        });
      }
    },

    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get(
          "/api/exam-grade?rowsPerPage=25&descending=true&slim=true&examId=" +
            this.exam
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return {
              value: item.grade_id,
              text: item.name,
              is_published: item.is_publish,
            };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getExams() {
      this.examLoading = true;
      this.$rest
        .get("/api/exam")
        .then(({ data }) => {
          this.exams = data.data.map((item) => {
            return { value: item.id, text: item.name, is_final: item.is_final };
          });
        })
        .finally(() => {
          this.examLoading = false;
        });
    },
    getSections() {
      this.$rest
        .get("/api/section?batch=" + this.batch.id + "&grade=" + this.grade)
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    printSingleModal(enrollId, printType) {
      this.issueDateDialog = true;
      this.singlePrintEnable = true;
      this.singlePrintEnrollId = enrollId;
      this.singlePrintType = printType;
    },
    printSingle(enrollId, printType = "terminal") {
      this.$rest
        .get("/api/print/mark-sheet" + "?print_type=" + this.singlePrintType)
        .then(({ data }) => {
          let url =
            data.data.download_url +
            "?examId=" +
            this.exam +
            "&gradeId=" +
            this.grade +
            "&sectionId=" +
            this.section +
            "&enrollId=" +
            this.singlePrintEnrollId +
            "&issueDate=" +
            this.issueDate.dateForm() +
            "&print_type=" +
            this.singlePrintType +
            "&type=gradesheet";

          if (this.markSheetType === "rating") url += "&marksheet_type=rating";
          passDataToExternalDomain("reporting");
          setTimeout(function() {
            window.open(url);
          }, 300);

          this.issueDateDialog = true;
        });
    },
    printBulk() {
      this.$rest
        .get("/api/print/mark-sheet" + "?print_type=" + this.printType)
        .then(({ data }) => {
          let url =
            data.data.download_url +
            "?examId=" +
            this.exam +
            "&gradeId=" +
            this.grade +
            "&sectionId=" +
            this.section +
            "&issueDate=" +
            this.issueDate.dateForm() +
            "&print_type=" +
            this.printType +
            "&type=gradesheet";
          if (this.markSheetType === "rating") url += "&marksheet_type=rating";
          window.open(url);
          this.issueDateDialog = true;
        });
    },

    printLedger(flag) {
      this.$rest.get("/api/download/marks-ledger").then(({ data }) => {
        let url =
          data.data.download_url +
          "?examId=" +
          this.exam +
          "&sectionId=" +
          this.section +
          "&gradeId=" +
          this.grade;
        if (flag) {
          url += "&download=true";
        }
        url += "&print_type=" + this.printType;

        window.open(url);
      });
    },
    addRemarks(item) {
      this.remarksDialog = true;
      this.remarkFor = item;
      this.remarks = item.remark ? item.remark.remarks : "";
      this.enroll_id = item.enroll_id;
    },
    submitRemarks() {
      this.$rest
        .post("/api/exam/student-remark", {
          enroll_id: this.enroll_id,
          section_id: this.section,
          grade_id: this.grade,
          exam_id: this.exam,
          remarks: this.remarks,
        })
        .then((data) => {
          this.$events.fire("notification", {
            message: data.data.message,
            status: "success",
          });
          this.remarksDialog = false;
          this.get();
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });

          // this.$events.fire("notificaiton", {
          //   message: "Success",
          //   status: "error",
          // });
          // this.remarksDialog = false;
        });
    },

    getGradeRatingStatus() {
      this.$rest.get("api/marksheet-type?gradeId=" + this.grade).then((res) => {
        this.markSheetType = res.data.marksheet_type;
      });
    },
    printECA(enrollID) {
      this.$rest.get("/api/print/eca-achievement").then(({ data }) => {
        let url =
          data.data.download_url +
          "?examId=" +
          this.exam +
          "&sectionId=" +
          this.section +
          "&enrollId=" +
          enrollID;
        passDataToExternalDomain("reporting");
        if (this.disablePdf === false) {
          this.disablePdf = true;
          let $this = this;
          setTimeout(function() {
            window.open(url);
            $this.disablePdf = false;
          }, 500);
        }

        // window.open(url)
      });
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.form.items.data.slice(0, this.smsLimit);
    },

    SMSModalshow() {
      this.sendSMSModal = true;
      this.selectedStudentIds = [];
      this.selected.map((res) => {
        this.selectedStudentIds.push(res.id);
      });
    },
    selectAllStudent() {
      if (this.selectedStudentIds.length === 0) {
        this.toggleAll();
      }
      this.selectedStudentIds = [];
      this.form.items.data.map((res) => {
        this.selectedStudentIds.push(res.id);
      });
    },
  },
};
</script>
<style lang="scss">
.marks_entry {
  input[disabled] {
    background-color: #eee;
    color: #ccc;
    cursor: not-allowed;
  }

  .mark_entry_input {
    border: 1px solid #ccc;
    font-weight: bold;
    border-radius: 3px;
    width: 60px;
    height: 25px;
    text-align: center;
  }

  .marks_entry_input_container {
    display: flex;
    align-items: center;
    justify-content: center;

    .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-input__control {
      display: -webkit-box !important;
      -webkit-box-orient: horizontal !important;
    }

    .v-input {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      display: -webkit-box !important;
      display: -ms-flexbox;
      /* display: flex; */
      /* -webkit-box-flex: 1; */
      -ms-flex: 1 1 auto;
      flex: none;
      font-size: 16px;
      text-align: left;
    }
  }

  input:focus {
    outline: none;
  }
}

#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

.subject-error {
  background: #f95050;
  color: white;
  padding: 0 5px;
  /*padding-right: 5px;*/
  border-radius: 2px;
  margin-left: 5px;
}
</style>
